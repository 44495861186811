import { immerable } from "immer";
import { ApiPhotoSessionArgs, addReadOnlyPropertiesToModel, addDateGetterAndSetterToDomainModel } from "avvir";

export default class PhotoSession {
  constructor({ id, photoAreaId, sessionDate, deletedAt }: ApiPhotoSessionArgs = {}) {
    addReadOnlyPropertiesToModel(this, { id, photoAreaId });
    addDateGetterAndSetterToDomainModel(this, "sessionDate", sessionDate);
    addDateGetterAndSetterToDomainModel(this, "deletedAt", deletedAt);
  }

  readonly id: number;
  readonly photoAreaId: number;
  readonly sessionDate: Date;
  deletedAt?: Date;

  static readonly [immerable] = true;
}
