import { Reducer } from "redux";
import { produce } from "immer";
import _ from "underscore";

import CloudFile from "../../../models/domain/cloud_file";
import { ByDatabaseId } from "type_aliases";
import { FILE_ASSOCIATIONS_UPDATED, FileAssociationsPayload, FileAssociationsUpdatedEvent } from "../../../events/uploaded/file_associations_updated";
import { FLOOR_FILE_UPLOADED, FloorFileUploadedEvent } from "../../../events/uploaded/floor_file_uploaded";
import { FLOOR_FILES_FOR_PROJECT_LOADED, FloorFilesForProjectLoadedEvent } from "../../../events/loaded/floors/floor_files_for_project_loaded";
import { FLOOR_FILES_LOADED, FloorFilesLoadedEvent } from "../../../events/loaded/floors/floor_files_loaded";
import { PHOTO_AREA_FILE_UPLOADED, PhotoAreaFileUploadedEvent } from "../../../events/uploaded/photo_area_file_uploaded";
import { PHOTO_AREA_FILES_LOADED, PhotoAreaFilesLoadedEvent } from "../../../events/loaded/photos/photo_area_files_loaded";
import { PROJECT_FILE_UPLOADED, ProjectFileUploadedEvent } from "../../../events/uploaded/project_file_uploaded";
import { PROJECT_FILES_LOADED, ProjectFilesLoadedEvent } from "../../../events/loaded/projects/project_files_loaded";
import { SCAN_DATASET_FILE_UPLOADED, ScanDatasetFileUploadedEvent } from "../../../events/uploaded/scan_dataset_file_uploaded";
import { SCAN_DATASET_FILES_FOR_PROJECT_LOADED, ScanDatasetFilesForProjectLoadedEvent } from "../../../events/loaded/scan_datasets/scan_dataset_files_for_project_loaded";
import { SCAN_DATASET_FILES_LOADED, ScanDatasetFilesLoadedEvent } from "../../../events/loaded/scan_datasets/scan_dataset_files_loaded";
import { FILE_ASSOCIATIONS_UPDATED_RETAIN_FLOOR_FILES, FileAssociationsUpdatedRetainedFloorFilesEvent } from "../../../events/uploaded/file_associtions_updated_retain_floor_files";

type FilesEvents =
  | ProjectFilesLoadedEvent
  | ProjectFileUploadedEvent
  | FloorFilesLoadedEvent
  | FloorFilesForProjectLoadedEvent
  | FloorFileUploadedEvent
  | ScanDatasetFilesLoadedEvent
  | ScanDatasetFilesForProjectLoadedEvent
  | ScanDatasetFileUploadedEvent
  | PhotoAreaFilesLoadedEvent
  | PhotoAreaFileUploadedEvent
  | FileAssociationsUpdatedEvent
  | FileAssociationsUpdatedRetainedFloorFilesEvent;

export type FilesStore = {
  byId: ByDatabaseId<CloudFile>
};

const reduceFilesUponFileAssociationsUpdated = (payload: FileAssociationsPayload, filesDraft: any) => {
  const newFileIds = payload.files.reduce((ids, file) => {
    ids.add(file.id);
    return ids;
  }, new Set());

  payload.previousFiles.forEach((file) => {
    if (!newFileIds.has(file.id)) {
      delete filesDraft.byId[file.id];
    }
  });

  payload.files.forEach((file) => {
    filesDraft.byId[file.id] = new CloudFile(file);
  });
}

const reduceFiles: Reducer<FilesStore, FilesEvents> = (files: FilesStore = { byId: {} }, event) => {
  return produce<FilesStore>(files, (filesDraft) => {
    if (!files.byId) {
      files.byId = {};
    }
    switch (event.type) {
      case FILE_ASSOCIATIONS_UPDATED: {
        reduceFilesUponFileAssociationsUpdated(event.payload, filesDraft);
        break;
      }
      case FILE_ASSOCIATIONS_UPDATED_RETAIN_FLOOR_FILES: {
        reduceFilesUponFileAssociationsUpdated(event.payload, filesDraft);
        break;
      }
      case PROJECT_FILES_LOADED:
      case SCAN_DATASET_FILES_LOADED:
      case PHOTO_AREA_FILES_LOADED: {
        event.payload.files.forEach(file => {
          filesDraft.byId[file.id] = new CloudFile(file);
        });
        break;
      }
      case FLOOR_FILES_LOADED: {
        _.forEach(event.payload.files, (files) => {
          if (Array.isArray(files)) {
            files.forEach((file) => {
              filesDraft.byId[file.id] = new CloudFile(file);
            });
          } else if (files) {
            filesDraft.byId[files.id] = new CloudFile(files);
          }
        });
        break;
      }
      case FLOOR_FILES_FOR_PROJECT_LOADED: {
        event.payload.forEach(({ floorId, files }) => {
          return files.forEach((apiFile) => {
            filesDraft.byId[apiFile.id] = new CloudFile(apiFile);
          });
        });
        break;
      }
      case SCAN_DATASET_FILES_FOR_PROJECT_LOADED: {
        _(event.payload).forEach((scanDatasetFiles) => {
          scanDatasetFiles.forEach((apiFile) => {
            filesDraft.byId[apiFile.id] = new CloudFile(apiFile);
          });
        });
        break;
      }
      case PROJECT_FILE_UPLOADED:
      case FLOOR_FILE_UPLOADED:
      case SCAN_DATASET_FILE_UPLOADED:
      case PHOTO_AREA_FILE_UPLOADED: {
        filesDraft.byId[event.payload.file.id] = new CloudFile(event.payload.file);
        break;
      }
    }
  });
};

export default reduceFiles;
