import React, { FunctionComponent, MouseEvent, useCallback, useMemo } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MenuItem, Typography } from "@mui/material";
import { Layers } from "@mui/icons-material";
import _ from "underscore";

import "../../../../css/nav_bar/nav_bar_floor_dropdown.css";
import DateConverter from "../../../services/converters/date_converter";
import getFloorId from "../../../services/getters/floor_getters/get_floor_id";
import getProjectId from "../../../services/getters/project_getters/get_project_id";
import NavBarDropdown from "../nav_bar_dropdown";
import ScrollableMenu from "../../scrollable_menu";
import TooltippedOnOverflow from "../../tooltipped_on_overflow";
import toViewerFloor from "../../../events/routing/to_viewer_floor";
import useFloorsForProject from "../../../queries/floors/use_floors_for_project";
import usePopover from "../../../services/component_helpers/component_effects/use_popover";

const formatter = DateConverter.getDateFormatter("MMM D, YYYY");

const convertScanDateText = (date: Date) => {
  if (date instanceof Date) {
    return "last scanned: " + formatter.formatUTC(date);
  } else {
    return "";
  }
};

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const NavBarFloorDropdown: FunctionComponent<Props> = (props) => {
  const {
    projectId,
    selectedFloorId,
    toViewerFloor
  } = props;
  const { data: floorsByFirebaseId } = useFloorsForProject(projectId);
  const [openState, anchorEl, handleOpen, onClose] = usePopover();

  const floors = useMemo(() => {
    return _.values(floorsByFirebaseId);
  }, [floorsByFirebaseId]);

  const selectedIndex = floors.findIndex((floor) => floor.firebaseId === selectedFloorId);

  const handleSelect = useCallback((event: MouseEvent<HTMLLIElement>) => {
    const floorId = event.currentTarget.dataset.value;
    toViewerFloor(projectId, floorId);
    onClose();
  }, [toViewerFloor, projectId, onClose]);

  const floorMenuItems = floors.map((floor) => {
    return <MenuItem
      key={`NavBarFloorDropdown-${floor.firebaseId}`}
      className="NavBarFloorDropdown-menuItem"
      selected={selectedFloorId === floor.firebaseId}
      onClick={handleSelect}
      data-value={floor.firebaseId}
    >
      <TooltippedOnOverflow><span className="NavBarFloorDropdown-menuItem-floorNumber">{floor.floorNumber}</span></TooltippedOnOverflow>
      <Typography className="NavBarFloorDropdown-menuItem-scanDate" variant="label">{convertScanDateText(floor.scanDate)}</Typography>
    </MenuItem>;
  });

  const selectedFloor = floorsByFirebaseId?.[selectedFloorId];
  return <NavBarDropdown
    label={<TooltippedOnOverflow>
        <span className="NavBarFloorDropdown-buttonText">
          {selectedFloor?.floorNumber}
        </span>
    </TooltippedOnOverflow>}
    className="NavBarFloorDropdown-button"
    popoverProps={{
      open: openState,
      anchorEl,
      handleOpen,
      onClose
    }}
    slots={{ menu: ScrollableMenu }}
    slotProps={{
      button: {
        "aria-label": "Floors Menu Button",
        startIcon: <Layers/>,
      },
      menu: {
        selectedIndex,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        }
      }
    }}
  >
    {floorMenuItems}
  </NavBarDropdown>;
};

const mapStateToProps = (state, props) => ({
  projectId: getProjectId(state, props),
  selectedFloorId: getFloorId(state, props)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toViewerFloor
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NavBarFloorDropdown);
