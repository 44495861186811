import queryString from "query-string";
import apiFailure from "../../events/notifications/failures/api_failure";
import checkFetchStatus from "../deprecated_gateway_api/check_fetch_status";
import getAuthorizationHeaders from "../deprecated_gateway_api/get_authorization_headers";
import { httpPostHeaders } from "../deprecated_gateway_api/request_headers";
import WebGatewayApi from "../deprecated_gateway_api/web_gateway_api";

const getErrorCallback = (dispatch, event = apiFailure) => {
  return (error) => {
    dispatch(event(error));
    throw error;
  };
};

export default class ProcoreApi {
  static baseUrl = process.env.REACT_APP_PROCORE_API_DOMAIN;
  static clientId = process.env.REACT_APP_PROCORE_CLIENT_ID;
  static oauthUrl = `${ProcoreApi.baseUrl}/oauth/authorize`;

  static getLoginUrl({ pdfType }) {
    const returnUri = `${window.location.origin}${window.location.pathname}`;
    const redirectUri = `${window.location.origin}/procore?pdfType=${pdfType}&returnUri=${encodeURIComponent(returnUri)}`;
    const params = queryString.stringify({
      response_type: "token",
      client_id: ProcoreApi.clientId,
      redirect_uri: redirectUri
    });

    return `${ProcoreApi.oauthUrl}?${params}`;
  };

  static checkProcoreAccessToken(projectId, procoreAccessToken, user, dispatch) {
    if (!projectId) {
      return Promise.reject(new Error("Project not loaded yet"));
    }
    const url = `${WebGatewayApi.baseUrl}/projects/${projectId}/procore`;
    const params = `?procore-access-token=${procoreAccessToken}`;
    return (fetch(url + params, {
      method: "GET",
      headers: {
        ...httpPostHeaders,
        ...getAuthorizationHeaders(user)
      },
    }).then(checkFetchStatus) as unknown as Promise<{expiresInSeconds: number}>)
      .catch(getErrorCallback(dispatch));
  }
}
