import { ApiBuiltStatus, ApiElementDeviation, ApiScannedElementTypeMap, isDeviationScanResult } from "avvir";
import { DateLike, ModifyPartial } from "type_aliases";

import addDateGetterAndSetterToDomainModel from "../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import Deviation from "./deviation";
import { UniformatId } from "uniformat";

type DetailedElementArgs<Label extends ApiBuiltStatus = ApiBuiltStatus> = ModifyPartial<DetailedElement<Label>, {
  builtAt: DateLike
  fixedAt: DateLike
  fixedDeviation: Deviation | ApiElementDeviation
}>

export default class DetailedElement<Label extends ApiBuiltStatus = ApiBuiltStatus> {
  constructor(detailedElement: DetailedElementArgs<Label> = {}) {
    addDateGetterAndSetterToDomainModel(this, "builtAt");
    addDateGetterAndSetterToDomainModel(this, "fixedAt");

    this.globalId = detailedElement.globalId;
    this.name = detailedElement.name;
    this.ifcType = detailedElement.ifcType;
    this.uniformat = detailedElement.uniformat;
    this.masterformat = detailedElement.masterformat;
    this.revitCategory = detailedElement.revitCategory;
    this.revitFamily = detailedElement.revitFamily;
    this.revitType = detailedElement.revitType;
    this.itemId = detailedElement.itemId;
    this.discipline = detailedElement.discipline;
    this.primaryUnitOfMeasurement = detailedElement.primaryUnitOfMeasurement;
    this.primaryMeasurement = detailedElement.primaryMeasurement;
    this.navisworksGuid = detailedElement.navisworksGuid;
    this.issueId = detailedElement.issueId;
    this.excludeFromAnalysis = detailedElement.excludeFromAnalysis;
    if (isDeviationScanResult(detailedElement.scanResult)) {
      // @ts-ignore
      this.scanResult = {
        scanLabel: detailedElement.scanResult?.scanLabel,
        deviation: new Deviation(detailedElement?.scanResult.deviation),
        detectedInScan: detailedElement.scanResult?.detectedInScan || false
      };
    } else if (detailedElement.scanResult) {
      // @ts-ignore
      this.scanResult = {
        scanLabel: detailedElement.scanResult.scanLabel,
        detectedInScan: detailedElement.scanResult.detectedInScan || false
      };
    }
    this.navisworksGuid = detailedElement.navisworksGuid;
    // @ts-ignore
    this.builtAt = detailedElement.builtAt;
    // @ts-ignore
    this.fixedAt = detailedElement.fixedAt;
    if (detailedElement.fixedDeviation) {
      this.fixedDeviation = { ...new Deviation({ ...detailedElement.fixedDeviation }), clashing: false };
    }
    if (detailedElement.isNew != null) {
      this.isNew = detailedElement.isNew;
    }
    this.verified = typeof detailedElement.verified === "undefined" ? false : detailedElement.verified;
    this.exportedToBcf = detailedElement.exportedToBcf;
    this.partialProgressPercent = detailedElement.partialProgressPercent;
  }

  name?: string;
  globalId: string;
  itemId?: string | null;
  ifcType?: string;
  discipline?: string;
  uniformat?: UniformatId;
  masterformat?: string;
  revitFamily?: string;
  revitType?: string;
  revitCategory?: string;
  primaryUnitOfMeasurement?: string;
  primaryMeasurement?: number;
  navisworksGuid?: string;
  issueId?: number;
  excludeFromAnalysis?: boolean;

  scanResult?: ApiScannedElementTypeMap[Label];
  builtAt?: number;
  fixedAt?: number;
  fixedDeviation?: ApiElementDeviation;
  isNew?: boolean;
  loaded?: boolean;
  verified?: boolean;
  exportedToBcf?: boolean;
  partialProgressPercent?: number;
}
