import { Action, Reducer } from "redux";
import { produce } from "immer";
import _ from "underscore";
import { ApiPipeline, AssociationType } from "avvir";

import CloudFile from "../../../models/domain/cloud_file";
import FileInformationConverter from "../../converters/file_information_converter";
import Floor from "../../../models/domain/floor";
import makeUpdateModel from "./make_update_model";
import PlannedBuildingElement from "../../../models/domain/planned_building_element";
import without from "../../utilities/general/without";
import { DEFAULT_SCAN_DATASET_FOR_FLOOR_UPDATED, DefaultScanDatasetForFloorUpdatedEvent } from "../../../events/superadmin/scan_datasets/default_scan_dataset_for_floor_updated";
import { ELEMENT_ACTION_RECORDED, ElementActionRecordedEvent } from "../../../events/viewer/element_action_recorded";
import { ELEMENT_ACTIONS_LOADED, ElementActionsLoadedEvent } from "../../../events/loaded/element_actions_loaded";
import { FILE_ASSOCIATIONS_UPDATED, FileAssociationsUpdatedEvent } from "../../../events/uploaded/file_associations_updated";
import { FLOOR_CREATED, FloorCreatedEvent } from "../../../events/superadmin/floors/floor_created";
import { FLOOR_DELETED, FloorDeletedEvent } from "../../../events/superadmin/floors/floor_deleted";
import { FLOOR_FILE_UPLOADED, FloorFileUploadedEvent } from "../../../events/uploaded/floor_file_uploaded";
import { FLOOR_FILES_FOR_PROJECT_LOADED, FloorFilesForProjectLoadedEvent } from "../../../events/loaded/floors/floor_files_for_project_loaded";
import { FLOOR_FILES_LOADED, FloorFilesLoadedEvent } from "../../../events/loaded/floors/floor_files_loaded";
import { FLOOR_LOADED, FloorLoadedEvent } from "../../../events/loaded/floors/floor_loaded";
import { FLOOR_MASTERFORMAT_PROGRESS_LOADED, FloorMasterformatProgressLoadedEvent } from "../../../events/loaded/floors/floor_masterformat_progress_loaded";
import { FLOOR_ORDER_UPDATED, FloorOrderUpdatedEvent } from "../../../events/superadmin/floors/floor_order_updated";
import { FLOOR_PIPELINES_LOADED, FloorPipelinesLoadedEvent } from "../../../events/loaded/floors/floor_pipelines_loaded";
import { FLOOR_UPDATED, FloorUpdatedEvent } from "../../../events/superadmin/floors/floor_updated";
import { FloorPurposeType } from "../../../models/domain/enums/purpose_type";
import { FLOORS_FOR_PROJECT_LOADED, FloorsForProjectLoadedEvent } from "../../../events/loaded/floors/floors_for_project_loaded";
import { OBSTRUCTIONS_CREATED, ObstructionsCreatedEvent } from "../../../events/viewer/obstructions_created";
import { OBSTRUCTIONS_DELETED, ObstructionsDeletedEvent } from "../../../events/viewer/obstructions_deleted";
import { OBSTRUCTIONS_LOADED, ObstructionsLoadedEvent } from "../../../events/loaded/building_elements/obstructions_loaded";
import { PHOTO_AREA_MINIMAP_TO_BIM_MINIMAP_TRANSFORM_SAVED, PhotoAreaMinimapToBimMinimapTransformSavedEvent } from "../../../events/photos/photo_area_minimap_to_bim_minimap_transform_saved";
import { PLANNED_BUILDING_ELEMENTS_LOADED, PlannedBuildingElementsLoadedEvent } from "../../../events/loaded/building_elements/planned_building_elements_loaded";
import { SCAN_DATASET_CREATED, ScanDatasetCreatedEvent } from "../../../events/superadmin/scan_datasets/scan_dataset_created";
import { SCAN_DATASET_DELETED, ScanDatasetDeletedEvent } from "../../../events/superadmin/scan_datasets/scan_dataset_deleted";
import { SCAN_DATASETS_CREATED_FROM_TSV, ScanDatasetsCreatedFromTsvEvent } from "../../../events/superadmin/scan_datasets/scan_datasets_created_from_tsv";

type FloorEvents =
  | DefaultScanDatasetForFloorUpdatedEvent
  | FloorCreatedEvent
  | FloorDeletedEvent
  | FloorFilesLoadedEvent
  | FloorFilesForProjectLoadedEvent
  | FloorFileUploadedEvent
  | FloorLoadedEvent
  | FloorPipelinesLoadedEvent
  | FloorsForProjectLoadedEvent
  | FloorUpdatedEvent
  | PhotoAreaMinimapToBimMinimapTransformSavedEvent
  | ElementActionsLoadedEvent
  | ElementActionRecordedEvent
  | ScanDatasetCreatedEvent
  | ScanDatasetDeletedEvent
  | ScanDatasetsCreatedFromTsvEvent
  | FloorOrderUpdatedEvent
  | FileAssociationsUpdatedEvent
  | Action<"load_floor_for_viewer_started">
  | Action<"load_floor_for_viewer_done">
  | ObstructionsLoadedEvent
  | ObstructionsCreatedEvent
  | ObstructionsDeletedEvent
  | FloorMasterformatProgressLoadedEvent
  | PlannedBuildingElementsLoadedEvent

export interface FloorsStore {
  byFirebaseId: { [firebaseId: string]: Floor };
  pipelines: ApiPipeline[];
  floorForViewerLoading?: boolean;
  loadedFloorForViewerFirebaseId?: string;
}

const addFileToFloor = (floors: FloorsStore, floorId: string, file: CloudFile) => updateFloor(floors, floorId, floor => produce(floor, (floorDraft) => {
  if (file.purposeType === FloorPurposeType.OTHER) {
    floorDraft.files[file.purposeType] = _.union(floor.files?.[file.purposeType], [file.id]);
  } else {
    floorDraft.files[file.purposeType] = [file.id];
  }
}));

const removeFileFromFloor = (floors: FloorsStore, floorId: string, file: CloudFile) => updateFloor(floors, floorId, floor => produce(floor, (floorDraft) => {
  floorDraft.files[file.purposeType] = without(floorDraft.files?.[file.purposeType], file.id);
}));

const emptyFloor = new Floor();

const updateFloor = makeUpdateModel<Floor>(emptyFloor);

const removeFloor = (floors: FloorsStore, floorFirebaseId: string): FloorsStore => {
  return produce(floors, (floorsDraft) => {
    delete floorsDraft.byFirebaseId[floorFirebaseId];
  });
};

const reduceFloors: Reducer<FloorsStore, FloorEvents> = (floors: FloorsStore = { byFirebaseId: {}, pipelines: [] }, event) => {
  if (!floors?.byFirebaseId) {
    floors = { byFirebaseId: {}, pipelines: [] };
  }
  switch (event.type) {
    case FLOOR_CREATED: {
      const addedFloor = new Floor(event.payload);
      return updateFloor(floors, addedFloor.firebaseId, addedFloor);
    }
    case PHOTO_AREA_MINIMAP_TO_BIM_MINIMAP_TRANSFORM_SAVED: {
      return updateFloor(floors, event.payload.floor.firebaseId, event.payload.floor);
    }
    case FLOOR_UPDATED: {
      return updateFloor(floors, event.payload.floor.firebaseId, event.payload.floor);
    }
    case FLOOR_DELETED: {
      return removeFloor(floors, event.payload.floorId);
    }
    case FLOOR_FILES_LOADED: {
      return updateFloor(floors, event.payload.floorId, {
        files: FileInformationConverter.fileIdsFromApi(event.payload.files)
      });
    }
    case FLOOR_FILES_FOR_PROJECT_LOADED: {
      return event.payload.reduce((floorsSoFar, { floorId, files }) => {
        return updateFloor(floorsSoFar, floorId, (originalFloor) => ({
          files: {
            ...originalFloor.files,
            ...files.reduce((filesSoFar, apiFile) => {
              const file = new CloudFile(apiFile);
              if (filesSoFar[file.purposeType]) {
                filesSoFar[file.purposeType] = _.union(filesSoFar[file.purposeType], [file.id]);
              } else {
                filesSoFar[file.purposeType] = [file.id];
              }
              return filesSoFar;
            }, {})
          }
        }));
      }, floors);
    }
    case FILE_ASSOCIATIONS_UPDATED: {
      const previousFloorFiles = event.payload.previousFiles.filter((file) => file.associationType === AssociationType.FLOOR);
      const floorFiles = event.payload.files.filter((file) => file.associationType === AssociationType.FLOOR);
      const idMap = {};

      if (floorFiles.length === 0 && previousFloorFiles.length === 0) {
        return floors;
      }

      let updatedStore = floors;

      const updateFloorIdMap = (file) => {
        let floorId = idMap[file.associationId];
        if (floorId == null) {
          const foundFloor = Object.values(updatedStore.byFirebaseId).find((floor) => floor.id === file.associationId);
          if (foundFloor != null) {
            idMap[file.associationId] = foundFloor.firebaseId;
          }
        }
      };

      previousFloorFiles.forEach(updateFloorIdMap);
      floorFiles.forEach(updateFloorIdMap);

      previousFloorFiles.forEach((previousFile) => {
        const floorId = idMap[previousFile.associationId];
        if (floorId != null) {
          updatedStore = removeFileFromFloor(updatedStore, floorId, new CloudFile(previousFile));
        }
      });

      floorFiles.forEach((file) => {
        const floorId = idMap[file.associationId];
        if (floorId != null) {
          updatedStore = addFileToFloor(updatedStore, floorId, new CloudFile(file));
        }
      });

      return { ...updatedStore };
    }
    case FLOOR_FILE_UPLOADED: {
      return addFileToFloor(floors, event.payload.floorId, new CloudFile(event.payload.file));
    }
    case DEFAULT_SCAN_DATASET_FOR_FLOOR_UPDATED: {
      return updateFloor(floors, event.payload.floorId, {
        defaultFirebaseScanDatasetId: event.payload.scanDatasetId,
      });
    }
    case SCAN_DATASET_CREATED: {
      return updateFloor(floors, event.payload.firebaseFloorId, (floor) => ({
        firebaseScanDatasetIds: _.union(floor.firebaseScanDatasetIds, [event.payload.firebaseId])
      }));
    }
    case SCAN_DATASETS_CREATED_FROM_TSV: {
      return event.payload.reduce((floorsSoFar, scanDataset) => {
        return updateFloor(floorsSoFar, scanDataset.firebaseFloorId, (floor) => ({
          firebaseScanDatasetIds: _.union(floor.firebaseScanDatasetIds, [scanDataset.firebaseId])
        }));
      }, floors);
    }
    case SCAN_DATASET_DELETED: {
      return updateFloor(floors, event.payload.floorId, (floor) => {
        return {
          ...floor,
          defaultFirebaseScanDatasetId: event.payload.newDefaultScanDatasetId || floor.defaultFirebaseScanDatasetId,
          firebaseScanDatasetIds: without(floor.firebaseScanDatasetIds, event.payload.scanDatasetId)
        };
      });
    }
    case "load_floor_for_viewer_started": {
      return {
        ...floors,
        floorForViewerLoading: true
      };
    }
    case "load_floor_for_viewer_done": {
      return {
        ...floors,
        floorForViewerLoading: false,
      };
    }
    case FLOOR_LOADED: {
      const loadedFloor = new Floor(event.payload.floor);
      return updateFloor(floors, loadedFloor.firebaseId, (currentFloor) => ({
        ...currentFloor,
        ...loadedFloor,
        files: {
          ...currentFloor.files,
          ...loadedFloor.files
        }
      }));
    }
    case FLOORS_FOR_PROJECT_LOADED: {
      return event.payload.reduce((floorsSoFar, floor) => {
        const loadedFloor = new Floor(floor);
        return updateFloor(floorsSoFar, floor.firebaseId, (currentFloor) => ({
          ...currentFloor,
          ...loadedFloor,
          files: {
            ...currentFloor.files,
            ...loadedFloor.files
          },
          obstructions: {
            ...currentFloor.obstructions,
            ...loadedFloor.obstructions
          },
        }));
      }, { ...floors });
    }
    case FLOOR_PIPELINES_LOADED: {
      return {
        ...floors,
        pipelines: event.payload.pipelines
      };
    }
    case ELEMENT_ACTIONS_LOADED: {
      return updateFloor(floors, event.payload.floorId, (currentFloor) => {
        if (currentFloor) {
          return {
            elementActionsByGlobalId: {
              ...currentFloor.elementActionsByGlobalId,
              [event.payload.globalId]: event.payload.actionHistory
            }
          };
        }

        return currentFloor;
      });
    }
    case ELEMENT_ACTION_RECORDED: {
      return updateFloor(floors, event.payload.floorId, (currentFloor) => {
        if (currentFloor) {
          const existingActions = currentFloor.elementActionsByGlobalId[event.payload.globalId];
          const elementActions = existingActions == null ? [] : existingActions.slice();
          elementActions.unshift(event.payload.action);

          return {
            elementActionsByGlobalId: {
              ...currentFloor.elementActionsByGlobalId,
              [event.payload.globalId]: elementActions
            }
          };
        }

        return currentFloor;
      });
    }
    case FLOOR_ORDER_UPDATED: {
      return event.payload.floorIds.reduce((floorsSoFar, floorId, currentIndex) => {
        return updateFloor(floorsSoFar, floorId, { ordinal: currentIndex + 1 });
      }, { ...floors });
    }
    case OBSTRUCTIONS_LOADED: {
      return updateFloor(floors, event.payload.firebaseFloorId, (floor) => {
        const newObstructionsByGlobalId = event.payload.obstructingElementGlobalIds.reduce((obstructionsSoFar, globalId) => {
          return {
            ...obstructionsSoFar,
            [globalId]: event.payload.obstructions[globalId] || null
          };
        }, floor?.obstructions?.byObstructingElementGlobalId);
        return new Floor({
          ...floor,
          obstructions: {
            byObstructingElementGlobalId: newObstructionsByGlobalId
          }
        });
      });
    }
    case OBSTRUCTIONS_CREATED: {
      return updateFloor(floors, event.payload.floorId, (floor) => {
        return new Floor({
          ...floor,
          obstructions: {
            byObstructingElementGlobalId: {
              ...floor?.obstructions?.byObstructingElementGlobalId,
              ...event.payload.obstructions
            }
          }
        });
      });
    }
    case OBSTRUCTIONS_DELETED: {
      return updateFloor(floors, event.payload.floorId, (floor) => {
        return new Floor({
          ...floor,
          obstructions: {
            byObstructingElementGlobalId: {
              ...floor?.obstructions?.byObstructingElementGlobalId,
              ...event.payload.obstructions
            }
          }
        });
      });
    }
    case FLOOR_MASTERFORMAT_PROGRESS_LOADED: {
      if (event.payload.scanned?.length || event.payload.baseline?.length || event.payload.current?.length) {
        return updateFloor(floors, event.payload.floorId, (floor) => {
          return new Floor({
            ...floor,
            scannedProjectMasterformatProgresses: event.payload.scanned,
            baselineProjectMasterformatProgresses: event.payload.baseline,
            currentProjectMasterformatProgresses: event.payload.current,
          });
        });
      } else {
        return floors;
      }
    }
    case PLANNED_BUILDING_ELEMENTS_LOADED: {
      return updateFloor(floors, event.payload.floorId, floor => {
        const elementTradeCodesInBim = {};
        event.payload.elements.forEach(element => {
          const createdElement = new PlannedBuildingElement(element);
          if (createdElement.masterformat != null) {
            elementTradeCodesInBim[createdElement.masterformat] = true;
          }

          if (createdElement.trade != null) {
            elementTradeCodesInBim[createdElement.trade] = true;
          }

          if (createdElement.uniformat != null) {
            elementTradeCodesInBim[createdElement.uniformat] = true;
          }
        });
        return {
          ...floor,
          elementTradeCodesInBim
        };
      });
    }
    default: {
      return floors;
    }
  }
};

export default reduceFloors;
