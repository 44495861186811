import React, { FunctionComponent } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import "../../css/loading_overlay.css";

type Props = {
  progress?: number
}

const LoadingOverlay: FunctionComponent<Props> = (props) => {
  let spinner, progress;
  if (typeof props.progress === "undefined") {
    spinner = <CircularProgress className="LoadingOverlay-spinner indeterminate" size="80px" variant="indeterminate"/>;
    progress = null;
  } else {
    spinner = <CircularProgress className="LoadingOverlay-spinner determinate" size="80px" variant="determinate" value={props.progress}/>;
    progress = <span className="LoadingOverlay-progress">{`${props.progress.toFixed(1)}%`}</span>;
  }

  return <div className="LoadingOverlay">
    {spinner}
    {progress}
  </div>;
};

export default React.memo(LoadingOverlay);
