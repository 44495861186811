import useQueryWithState from "../use_query_with_state";
import loadWbsAvailability from "../../actions/wbs_page/load_wbs_availability";
import getWbsAvailability from "../../services/getters/wbs_getters/get_wbs_availability";

const useWbsAvailability = (projectId: string) => {
  return useQueryWithState(["useWbsAvailability", [projectId]],
    loadWbsAvailability,
    getWbsAvailability,
    { enabled: !!projectId },
    { projectId },
    projectId);
};

export default useWbsAvailability;
