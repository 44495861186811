import React, { FunctionComponent, MouseEvent, useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, ButtonGroup, Chip, MenuItem, Typography } from "@mui/material";
import { CalendarToday, ScatterPlot, CameraAlt } from "@mui/icons-material";
import { useQueryClient } from "react-query";
import _ from "underscore";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ApiScanDatasetQaState, UserPermissionAction, UserPermissionType } from "avvir";

import "../../../../css/nav_bar/nav_bar_scan_dropdown.css";
import DateConverter from "../../../services/converters/date_converter";
import getFloorId from "../../../services/getters/floor_getters/get_floor_id";
import getIsUserPermitted from "../../../services/getters/user_getters/get_is_user_permitted";
import getOrganizationId from "../../../services/getters/organization_getters/get_organization_id";
import getPhotoSessionIds from "../../../services/getters/photo_area_getters/get_photo_session_ids";
import getProjectIdFromLocation from "../../../services/getters/location_metadata/get_project_id_from_location";
import getScanDatasetId from "../../../services/getters/scan_dataset_getters/get_scan_dataset_id";
import NavBarDropdown from "../nav_bar_dropdown";
import NavBarQaStatusSelector from "./nav_bar_qa_status_selector";
import ScanDataset from "../../../models/domain/scan_dataset";
import ScrollableMenu from "../../scrollable_menu";
import selectPhotoSession from "../../../actions/viewer_page/select_photo_session";
import Tooltipable from "../../tooltipable";
import TooltippedOnOverflow from "../../tooltipped_on_overflow";
import toViewerScanDataset, { ToViewerScanDatasetEvent } from "../../../events/routing/to_viewer_scan_dataset";
import triggerQaStatisticsPipeline from "../../../actions/nav_bar/trigger_qa_statistics_pipeline";
import updateScanDatasetQaState from "../../../actions/nav_bar/update_scan_dataset_qa_state";
import useFloor from "../../../queries/floors/use_floor";
import usePhotoSessionsForPhotoArea from "../../../queries/photos/use_photo_sessions_for_photo_area";
import usePopover from "../../../services/component_helpers/component_effects/use_popover";
import usePrevious from "../../../services/component_helpers/component_effects/use_previous";
import useScanDatasetsForFloor from "../../../queries/scan_datasets/use_scan_datasets_for_floor";

import type { Dispatch } from "type_aliases";

const dateFormatter = DateConverter.getDateFormatter("MMM D, YYYY");

export const NavBarScanDropdown: FunctionComponent<Props> = (props) => {
  const {
    floorId,
    projectId,
    selectedScanDatasetId,
    toViewerScanDataset,
    triggerQaStatisticsPipeline,
    updateScanDatasetQaState,
    isUserPermitted,
    organizationId,
    photoSessionIds,
    selectPhotoSession
  } = props;
  const { qaStatusButton, scanDatasetSubtypesScanOrPhotos } = useFlags();
  const { data: floor } = useFloor(projectId, floorId);
  const { data: scanDatasets, isFetching, } = useScanDatasetsForFloor(projectId, floorId);
  const { data: photoSessions } = usePhotoSessionsForPhotoArea(projectId, floor?.photoAreaId);
  const [openState, anchorEl, handleOpen, onClose] = usePopover();
  const queryClient = useQueryClient();

  const photoSessionExistsByDate = useMemo(() => {
    const byDate = {};
    for (let sessionId in photoSessions) {
      const session = photoSessions[sessionId];
      const day = DateConverter.dateToUTCDayStringOrEmpty(session.sessionDate);
      if (day.length > 0) {
        byDate[day] = true;
      }
    }
    return byDate;
  }, [photoSessions]);

  const selectedPhotoSessionDate = useMemo(() => {
    return photoSessions?.[photoSessionIds[0]]?.sessionDate;
  }, [photoSessions, photoSessionIds]);

  const sortedScanDatasets = useMemo(() => {
    return _.chain(scanDatasets)
      .values()
      .filter((scan) => scan.scanDate != null)
      .sortBy("scanDate")
      .value();
  }, [scanDatasets]);
  const selectedIndex = sortedScanDatasets.findIndex(scan => scan.firebaseId === selectedScanDatasetId);

  const handleSelect = useCallback((event: MouseEvent<HTMLLIElement>) => {
    const scanDatasetId = event.currentTarget.dataset.value;
    toViewerScanDataset(projectId, floorId, scanDatasetId);
    const scanDataset = scanDatasets?.[scanDatasetId];
    if (!DateConverter.isOnDay(scanDataset.scanDate, selectedPhotoSessionDate)) {
      selectPhotoSession(scanDataset.scanDate);
    }
    onClose();
  }, [toViewerScanDataset, projectId, floorId, scanDatasets, selectedPhotoSessionDate, onClose, selectPhotoSession]);
  const previousPhotoSessionDate = usePrevious(selectedPhotoSessionDate || NaN);

  useEffect(() => {
    const scanDataset = scanDatasets?.[selectedScanDatasetId];
    if (!DateConverter.isOnDay(scanDataset?.scanDate, selectedPhotoSessionDate)) {
      selectPhotoSession(scanDataset?.scanDate);
    }
    // This is to set the selected photo session on page load, so it has no dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const scanDataset = scanDatasets?.[selectedScanDatasetId];
    if (!DateConverter.isOnDay(scanDataset?.scanDate, selectedPhotoSessionDate)) {
      if (selectedPhotoSessionDate == null && previousPhotoSessionDate != null) {
        selectPhotoSession(scanDataset?.scanDate);
      } else if (selectedPhotoSessionDate != null) {
        selectPhotoSession(scanDataset?.scanDate);
      }
    }
  }, [scanDatasets, selectedScanDatasetId, selectedPhotoSessionDate, previousPhotoSessionDate, selectPhotoSession]);

  const handleQaButtonClick = useCallback(async (scanDatasetId, currentStatus) => {
    const scanDataset = scanDatasets?.[scanDatasetId];
    const associationIds = {
      projectId,
      floorId,
      scanDatasetId: scanDataset.firebaseId,
    };
    if (isFetching) {
      queryClient.cancelQueries("useScanDatasetsForFloor")
        .then(async () => {
          await updateScanDatasetQaState(associationIds, ScanDataset.getNextQaState(currentStatus));
        });
    } else {
      await updateScanDatasetQaState(associationIds, ScanDataset.getNextQaState(currentStatus));
    }

    if (scanDataset.qaState !== ApiScanDatasetQaState.STARTED && scanDataset.qaState !== ApiScanDatasetQaState.COMPLETED) {
      triggerQaStatisticsPipeline({ projectId, floorId, scanDatasetId });
    }
  }, [isFetching, queryClient, scanDatasets, projectId, floorId, updateScanDatasetQaState, triggerQaStatisticsPipeline]);

  const scanDatasetsList = useMemo(() => sortedScanDatasets.map((scanDataset) => {
    let secondaryText = null;
    if (scanDataset.name) {
      secondaryText = <Typography className="NavBarScanDropdown-menuItem-scanDate" variant="label">
        {dateFormatter.formatUTC(scanDataset.scanDate)}
      </Typography>;
    }
    const scanDisplayText = scanDataset.name || dateFormatter.formatUTC(scanDataset.scanDate);
    const setQaStatusButton = <Tooltipable title={`Change QA/QC Status to ${ScanDataset.getNextQaState(scanDataset.qaState)}`}>
      <Button
        value={scanDataset.firebaseId}
        className={`NavBarScanDropdown-menuItem-qaCompleteCheckbox-${scanDataset.qaState}`}
        onClick={() => handleQaButtonClick(scanDataset.firebaseId, scanDataset.qaState)}
        disabled={scanDataset.qaState === ApiScanDatasetQaState.NO_DATA}
      >
        {scanDataset.qaState}
      </Button>
    </Tooltipable>;

    const scanDay = DateConverter.dateToUTCDayStringOrEmpty(scanDataset.scanDate);
    const photosExist = scanDay.length > 0 && photoSessionExistsByDate[scanDay];
    return <MenuItem
      key={`NavBarScanDropdown-${scanDataset.firebaseId}`}
      className="NavBarScanDropdown-menuItem"
      selected={selectedScanDatasetId === scanDataset.firebaseId}
      onClick={handleSelect}
      data-value={scanDataset.firebaseId}
    >
      <div className="NavBarScanDropdown-menuItem-container">
        {scanDatasetSubtypesScanOrPhotos ?
         <div className="NavBarScanDropdown-menuItem-subtypeIcon">
          {photosExist ? <Chip icon={<CameraAlt/>} label="Photo" size="small"/> : null}
          {scanDataset.hasScanFile ? <Chip icon={<ScatterPlot/>} label="Scan" size="small"/> : null}
        </div> : null }
        <div className="NavBarScanDropdown-menuItem-displayText">
          <TooltippedOnOverflow>
            {scanDisplayText}
          </TooltippedOnOverflow>
          {secondaryText}
        </div>
        {isUserPermitted({
          projectFirebaseId: projectId,
          organizationFirebaseId: organizationId,
          permissionType: UserPermissionType.PROJECT,
          permissionAction: UserPermissionAction.UPDATE_QA_QC_COMPLETE
        }) && !qaStatusButton ? setQaStatusButton : null}
      </div>
    </MenuItem>;
  }), [sortedScanDatasets, photoSessionExistsByDate, selectedScanDatasetId, handleSelect, scanDatasetSubtypesScanOrPhotos, isUserPermitted, projectId, organizationId, qaStatusButton, handleQaButtonClick]);

  const selectedScanDataset = scanDatasets[selectedScanDatasetId];
  const scanDatasetsButton = <NavBarDropdown
    className="NavBarScanDropdown-button"
    label={<TooltippedOnOverflow>
      <span className="NavBarScanDropdown-buttonText">
        {selectedScanDataset?.name || dateFormatter.formatUTC(selectedScanDataset?.scanDate)}
      </span>
    </TooltippedOnOverflow>}
    popoverProps={{
      open: openState,
      anchorEl,
      handleOpen,
      onClose
    }}
    slots={{ menu: ScrollableMenu }}
    slotProps={{
      button: {
        "aria-label": "Scan Dataset Menu Button",
        startIcon: <CalendarToday className="NavBarScanDropdown-buttonIcon"/>,
      },
      menu: {
        selectedIndex,
        className: "NavBarScanDropdown"
      }
    }}
  >
    {scanDatasetsList}
  </NavBarDropdown>;

  if (qaStatusButton && isUserPermitted({
    projectFirebaseId: projectId,
    organizationFirebaseId: organizationId,
    permissionType: UserPermissionType.PROJECT,
    permissionAction: UserPermissionAction.UPDATE_QA_QC_COMPLETE
  })) {
    return <ButtonGroup
      className="NavBarScanDropdown-button"
      variant="contained"
      disableElevation
      size="medium"
      color="secondary"
    >
      {scanDatasetsButton}
      <NavBarQaStatusSelector projectId={projectId} floorId={floorId} scanDatasetId={selectedScanDatasetId}/>
    </ButtonGroup>;
  } else {
    return scanDatasetsButton;
  }
};

const mapStateToProps = (state, props) => ({
  organizationId: getOrganizationId(state, props),
  projectId: getProjectIdFromLocation(state, props),
  floorId: getFloorId(state, props),
  photoSessionIds: getPhotoSessionIds(state, props),
  selectedScanDatasetId: getScanDatasetId(state, props),
  isUserPermitted: getIsUserPermitted(state, props),
});

type DispatchedTypes = ToViewerScanDatasetEvent
                       | ReturnType<typeof updateScanDatasetQaState | typeof triggerQaStatisticsPipeline>
                       | ReturnType<typeof selectPhotoSession>
const mapDispatchToProps = (dispatch: Dispatch<DispatchedTypes>) => bindActionCreators({
  toViewerScanDataset,
  updateScanDatasetQaState,
  triggerQaStatisticsPipeline,
  selectPhotoSession
}, dispatch);

export type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps, mapDispatchToProps)(NavBarScanDropdown);
