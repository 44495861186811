import Avvir, { ApiProjectArea, ApiProjectAreaWorkPackage, ApiRecipe, ApiRunningProcess, isFirebaseUser, isGatewayUser } from "avvir";

import ApiCapturedTradeCost from "../../models/api/api_captured_trade_cost";
import ApiPlannedTradeCost from "../../models/api/api_planned_trade_cost";
import ApiTrade from "../../models/api/api_trade";
import ApiTradeCost from "../../models/api/api_trade_cost";
import checkFetchStatus from "./check_fetch_status";
import getAuthorizationHeaders, { User } from "./get_authorization_headers";
import { httpGetHeaders, httpPostHeaders } from "./request_headers";

import type ApiWorkPackage from "../../models/api/api_work_package";
import type { AssociationIds } from "type_aliases";

/** @deprecated Use equivalent from "avvir" */
export default class WebGatewayApi {
  static baseUrl: string = process.env.REACT_APP_WEB_API_GATEWAY_DOMAIN;

  static addAuthToDownloadUrl(baseUrl: string, user: User): string {
    if (user) {
      if (isGatewayUser(user)) {
        return `${baseUrl}?auth=${user.gatewayUser.idToken}`;
      } else if (isFirebaseUser(user)) {
        return `${baseUrl}?firebaseAuth=${user.firebaseUser.idToken}`;
      }
    } else {
      return baseUrl;
    }
  }

  static saveTradeCostsTsv({ projectId }: AssociationIds, tsvContent: string, user: User) {
    const multipartFormData = new FormData();
    const file = new Blob([tsvContent], { type: "text/tab-separated-values" });
    multipartFormData.append("file", file, "file.tsv");

    const url = `${WebGatewayApi.baseUrl}/projects/${projectId}/trade-breakdown/planned-costs`;
    return (fetch(url, {
      method: "POST",
      headers: {
        ...getAuthorizationHeaders(user)
      },
      body: multipartFormData
    }).then(checkFetchStatus) as Promise<ApiRunningProcess>)
      .catch(error => {
        Avvir.api.config.sharedErrorHandler(error);
      });
  }

  static getTradeCostsTsvUrl({ projectId }: AssociationIds, fileName: string, user: User): string {
    const baseUrl = `${WebGatewayApi.baseUrl}/projects/${projectId}/trade-breakdown/${fileName}_planned-costs.tsv`;
    return WebGatewayApi.addAuthToDownloadUrl(baseUrl, user);
  }

  static listTrades({ projectId }: AssociationIds, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/trade-breakdown`, {
      method: "GET",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpGetHeaders
      }
    }).then(checkFetchStatus) as Promise<ApiTrade[]>)
      .catch(error => {
        Avvir.api.config.sharedErrorHandler(error);
      });
  }

  static listPlannedTradeCosts({ projectId }: AssociationIds, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/trade-breakdown/planned-costs`, {
      method: "GET",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpGetHeaders
      }
    }).then(checkFetchStatus) as Promise<ApiPlannedTradeCost[]>)
      .catch(error => {
        Avvir.api.config.sharedErrorHandler(error);
      });
  }

  static listCapturedTradeCosts({ projectId }: AssociationIds, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/trade-breakdown/captured-costs`, {
      method: "GET",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpGetHeaders
      }
    }).then(checkFetchStatus) as Promise<ApiCapturedTradeCost[]>)
      .catch(error => {
        Avvir.api.config.sharedErrorHandler(error);
      });
  }

  static listTradeCosts({ projectId }: AssociationIds, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/trade-breakdown/costs`, {
      method: "GET",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpGetHeaders
      }
    }).then(checkFetchStatus) as Promise<ApiTradeCost[]>)
      .catch(error => {
        Avvir.api.config.sharedErrorHandler(error);
      });
  }

  static updateTradeCostReportedQuantity({ projectId }: AssociationIds, tradeCode: string, reportedQuantity: number, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/trade-breakdown/${tradeCode}/reported-quantity`, {
      method: "PUT",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpPostHeaders
      },
      body: reportedQuantity?.toString()
    }).then(checkFetchStatus) as Promise<ApiTradeCost>);
  }

  static createTradeRecipe({ projectId }: AssociationIds, apiRecipe: ApiRecipe, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/trade-recipes`, {
      method: "POST",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpPostHeaders
      },
      body: JSON.stringify(apiRecipe)
    }).then(checkFetchStatus) as Promise<ApiRecipe>);
  }

  static listProjectTradeRecipes({ projectId }: AssociationIds, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/trade-recipes`, {
      method: "GET",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpGetHeaders
      }
    }).then(checkFetchStatus) as Promise<ApiRecipe[]>);
  }

  static updateProjectRecipe({ projectId }: AssociationIds, apiRecipe: ApiRecipe, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/trade-recipes/${apiRecipe.id}`, {
      method: "PUT",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpPostHeaders
      },
      body: JSON.stringify(apiRecipe)
    }).then(checkFetchStatus) as Promise<ApiRecipe>);
  }

  static deleteProjectRecipe({ projectId }: AssociationIds, recipeId: number, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/trade-recipes/${recipeId}`, {
      method: "DELETE",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpGetHeaders
      }
    }).then(checkFetchStatus) as Promise<void>);
  }

  static deleteProjectRecipeStep({ projectId }: AssociationIds, recipeId: number, stepId: number, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/trade-recipes/${recipeId}/steps/${stepId}`, {
      method: "DELETE",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpGetHeaders
      }
    }).then(checkFetchStatus) as Promise<ApiRecipe>);
  }

  static listWorkPackages({ projectId }: AssociationIds, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/work-packages/list`, {
      method: "GET",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpGetHeaders
      }
    }).then(checkFetchStatus) as Promise<ApiWorkPackage[]>);
  }

  static createWorkPackage({ projectId }: AssociationIds, apiWorkPackage: ApiWorkPackage, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/work-packages`, {
      method: "POST",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpPostHeaders
      },
      body: JSON.stringify(apiWorkPackage)
    }).then(checkFetchStatus) as Promise<ApiWorkPackage>);
  }

  static deleteWorkPackage({ projectId }: AssociationIds, workPackageId: number, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/work-packages/${workPackageId}`, {
      method: "DELETE",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpGetHeaders
      }
    }).then(checkFetchStatus) as Promise<void>);
  }

  static updateWorkPackage({ projectId }: AssociationIds, workPackageId: number, apiWorkPackage: ApiWorkPackage & { trades: string[] }, user: User) {
    return (fetch(`${WebGatewayApi.baseUrl}/projects/${projectId}/work-packages/${workPackageId}`, {
      method: "PATCH",
      headers: {
        ...getAuthorizationHeaders(user),
        ...httpPostHeaders
      },
      body: JSON.stringify(apiWorkPackage)
    }).then(checkFetchStatus) as Promise<ApiWorkPackage>);
  }
}
