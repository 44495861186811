import { Dispatch, SetStateAction, useCallback } from "react";
import { useDebounce } from "@react-hook/debounce";

const useLocalStorage = function useLocalStorage<Type>(key: string, defaultValue?: Type): [[Type, Dispatch<SetStateAction<Type | undefined>>][0], ((data: Type | ((prevState: Type) => Type)) => void), (() => void)] {
  const [storage, debouncedSetStorage, setStorage] = useDebounce<Type>(() => {
    const data = window.localStorage.getItem(key);

    // Local storage is supposed to return null when nothing exists for a key
    if (data === null && defaultValue != null) {
      return defaultValue;
    }

    let storedData: Type;
    try {
      storedData = JSON.parse(data);
    } catch (e) {
      if (defaultValue == null) {
        console.warn(`Unable to read from local storage with key '${key}'`);
        storedData = null;
      } else {
        storedData = defaultValue;
      }
    }
    return storedData;
  }, 500, true);

  const setLocalStorage = useCallback((value: SetStateAction<Type>) => {
    debouncedSetStorage((previousValue) => {
      let nextValue: Type
      if (typeof value === "function") {
        // @ts-ignore
        nextValue =  value(previousValue);
      } else {
        nextValue = value;
      }

      const nextJson = JSON.stringify(nextValue);
      const previousJson = JSON.stringify(previousValue);
      if (nextJson !== previousJson) {
        window.localStorage.setItem(key, nextJson);
      }

      return nextValue;
    });
  }, [key, debouncedSetStorage]);

  const remove = useCallback(() => {
    try {
      window.localStorage.removeItem(key);
      setStorage(null);
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw.
    }
  }, [key, setStorage]);
  return [storage, setLocalStorage, remove];

};

export default useLocalStorage;
