import React, { FunctionComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "../../../css/nav_bar/nav_bar_controls_explorer.css";
import downloadDeviatedTsv from "../../actions/viewer_page/download_deviated_tsv";
import exportIfc from "../../actions/viewer_page/export_ifc";
import exportProgressReport from "../../actions/viewer_page/export_progress_report";
import getAllScanDatasetScanDates from "../../services/getters/scan_dataset_getters/get_all_scan_dataset_scan_dates";
import getExportStarted from "../../services/getters/get_export_started";
import NavBarFloorDropdown from "./shared/nav_bar_floor_dropdown";
import NavBarKebabMenu from "./nav_bar_kebab_menu";
import NavBarPanelsMenu from "./nav_bar_panels_menu";
import NavBarScanDropdown from "./shared/nav_bar_scan_dropdown";
import getProcoreAccessToken from "../../actions/viewer_page/procore/get_procore_access_token";
import { NavBarExportDropdownMenu } from "./nav_bar_export_dropdown_menu";
import getProjectIdFromLocation from "../../services/getters/location_metadata/get_project_id_from_location";
import exportBatchIfc from "../../actions/viewer_page/export_batch_ifc";

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const NavBarControlsExplorer: FunctionComponent<Props> = (props) => {
  const {
    exportStarted,
    exportIfc,
    exportBatchIfc,
    downloadDeviatedTsv,
    allScanDates,
    exportProgressReport,
    getProcoreAccessToken,
    projectId
  } = props;

  return <>
    <NavBarFloorDropdown/>
    <NavBarScanDropdown/>
    <NavBarPanelsMenu/>
    <NavBarExportDropdownMenu exportStarted={exportStarted} allScanDates={allScanDates} exportIfc={exportIfc} exportBatchIfc={exportBatchIfc}
                              downloadDeviatedTsv={downloadDeviatedTsv} exportProgressReport={exportProgressReport}
                              getProcoreAccessToken={getProcoreAccessToken} projectId={projectId}/>
    <NavBarKebabMenu />
  </>
};

const mapStateToProps = (state, props) => ({
  exportStarted: getExportStarted(state, props),
  allScanDates: getAllScanDatasetScanDates(state, props),
  projectId: getProjectIdFromLocation(state, {})
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  exportIfc,
  exportBatchIfc,
  downloadDeviatedTsv,
  exportProgressReport,
  getProcoreAccessToken,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NavBarControlsExplorer);