import { Reducer } from "redux";
import _ from "underscore";

import { StringIdFilter, toggleAllElements, toggleElement } from "../../utilities/filter";
import { TRADE_HIDDEN, TradeHiddenEvent } from "../../../events/selection/trades/trade_hidden";
import { TRADE_HIDDEN_ALL, TradeHiddenAllEvent } from "../../../events/selection/trades/trade_hidden_all";
import { TRADE_SHOWN, TradeShownEvent } from "../../../events/selection/trades/trade_shown";
import { TRADE_SHOWN_ALL, TradeShownAllEvent } from "../../../events/selection/trades/trade_shown_all";
import { TRADE_SHOWN_ONLY, TradeShownOnlyEvent } from "../../../events/selection/trades/trade_shown_only";
import { TRADES_LOADED, TradesLoadedEvent } from "../../../events/loaded/trade/trades_loaded";
import { WORK_PACKAGE_HIDDEN, WorkPackageHiddenEvent } from "../../../events/selection/work_packages/work_package_hidden";
import { WORK_PACKAGE_SHOWN, WorkPackageShownEvent } from "../../../events/selection/work_packages/work_package_shown";


import type { Modify, Selected } from "type_aliases";

export type TradesFilterEvents =
  | TradesLoadedEvent
  | TradeShownEvent
  | TradeHiddenEvent
  | TradeShownAllEvent
  | TradeHiddenAllEvent
  | TradeShownOnlyEvent
  | WorkPackageShownEvent
  | WorkPackageHiddenEvent;

export type TradesFilterStore = Modify<StringIdFilter, {
  [code: string]: {
    id: string
    selected: Selected,
    parentId: string,
    childIds: string[],
    workPackageId: number
  }
  unclassified: {
    id: "unclassified"
    selected: 0 | 1
    parentId: null
    childIds: []
    workPackageId: null
  }
}>

const reduceTradesFilter: Reducer<TradesFilterStore, TradesFilterEvents> = (tradesFilter: TradesFilterStore = {
  unclassified: {
    id: "unclassified",
    selected: 1,
    parentId: null,
    childIds: [],
    workPackageId: null
  }
}, event = { type: null }) => {
  switch (event.type) {
    case TRADES_LOADED: {
      return {
        ...tradesFilter,
        ...event.payload.trades.reduce((tradesSoFar, trade) => {
          tradesSoFar[trade.code] = {
            id: trade.code,
            selected: 1,
            childIds: trade.childCodes,
            ...(trade.parentCode && { parentId: trade.parentCode }),
            ...(trade.workPackageId && { workPackageId: trade.workPackageId }),
          };
          return tradesSoFar;
        }, {})
      };
    }
    case TRADE_HIDDEN: {
      return toggleElement(event.payload.code, 0, tradesFilter);
    }
    case TRADE_SHOWN: {
      return toggleElement(event.payload.code, 1, tradesFilter);
    }
    case TRADE_HIDDEN_ALL: {
      return toggleAllElements(tradesFilter, 0);
    }
    case TRADE_SHOWN_ALL: {
      return toggleAllElements(tradesFilter, 1);
    }
    case TRADE_SHOWN_ONLY: {
      return toggleElement(event.payload.code, 1, toggleAllElements(tradesFilter, 0));
    }
    case WORK_PACKAGE_HIDDEN: {
      const codesToHide = _(tradesFilter).chain()
        .where({ workPackageId: event.payload.workPackageId })
        .pluck("id")
        .value();
      return codesToHide.reduce((tradesSoFar, trade) => {
        return toggleElement(trade, 0, tradesSoFar);
      }, tradesFilter);
    }
    case WORK_PACKAGE_SHOWN: {
      const codesToShow = _(tradesFilter).chain()
        .where({ workPackageId: event.payload.workPackageId })
        .pluck("id")
        .value();
      return codesToShow.reduce((tradesSoFar, trade) => {
        return toggleElement(trade, 1, tradesSoFar);
      }, tradesFilter);
    }
    default: {
      return tradesFilter;
    }
  }
};

export default reduceTradesFilter;

