import { ApiBuiltStatus } from "avvir";

import addDateGetterAndSetterToDomainModel from "../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import DateConverter from "../../services/converters/date_converter";
import Deviation, { DeviationArgs } from "./deviation";
import { DateLike, ModifyPartial } from "type_aliases";
import { DEVIATED, IN_PLACE, NOT_BUILT } from "./enums/scan_label";
import { UniformatId } from "uniformat";

export type PlannedBuildingElementArg = ModifyPartial<PlannedBuildingElement, {
  builtAt: DateLike
  fixedAt: DateLike
  deviation: DeviationArgs
  fixedDeviation: DeviationArgs
  fixedAtDeviation: DeviationArgs
}>
export default class PlannedBuildingElement {
  constructor(args: PlannedBuildingElementArg = {}) {
    addDateGetterAndSetterToDomainModel(this, "builtAt", args.builtAt);
    addDateGetterAndSetterToDomainModel(this, "fixedAt", args.fixedAt);
    this.globalId = args.globalId;
    this.name = args.name;
    this.ifcType = args.ifcType;
    this.uniformat = args.uniformat;
    this.masterformat = args.masterformat;
    this.revitCategory = args.revitCategory;
    this.revitFamily = args.revitFamily;
    this.revitType = args.revitType;
    this.itemId = args.itemId;
    this.discipline = args.discipline;
    this.primaryUnitOfMeasurement = args.primaryUnitOfMeasurement;
    this.primaryMeasurement = args.primaryMeasurement;
    this.navisworksGuid = args.navisworksGuid;
    this.issueId = args.issueId;
    this.excludeFromAnalysis = args.excludeFromAnalysis;
    this.floorSpan = args.floorSpan;
    this.subcontractorId = args.subcontractorId;
    this.unitCost = args.unitCost;
    this.builtStatus = args.builtStatus;
    this.loaded = args.loaded;
    if (args.deviation) {
      this.deviation = { ...new Deviation(args.deviation) };
    }
    if (args.fixedDeviation) {
      this.fixedDeviation = { ...new Deviation(args.fixedDeviation) };
    }
    if (args.isNew != null) {
      this.isNew = args.isNew;
    }
    this.verified = typeof args.verified === "undefined" ? false : args.verified;
    this.reviztoId = args.reviztoId;
    this.reviztoIfcGuid = args.reviztoIfcGuid;
    this.exportedToBcf = args.exportedToBcf;
    this.partialProgressPercent = args.partialProgressPercent;
    this.trade = args.trade;
  }

  name?: string;
  globalId: string;
  itemId?: string | null;
  ifcType?: string;
  discipline?: string;
  uniformat?: UniformatId;
  masterformat?: string;
  revitFamily?: string;
  revitType?: string;
  revitCategory?: string;
  primaryUnitOfMeasurement?: string;
  primaryMeasurement?: number;
  navisworksGuid?: string;
  issueId?: number;
  excludeFromAnalysis?: boolean;
  floorSpan?: number;
  subcontractorId?: number;
  unitCost?: number;
  isNew?: boolean;
  /** In most cases, do not use this directly - use getScanLabel() */
  builtStatus?: ApiBuiltStatus;
  loaded?: boolean;
  /** In most cases, do not use this directly - use isBuilt() */
  builtAt: Date;
  /** In most cases, do not use this directly - use getDeviation() */
  deviation: Deviation;
  /** In most cases, do not use this directly - use isFixed() */
  fixedAt: Date;
  /** In most cases, do not use this directly - use getDeviation() */
  fixedDeviation: Deviation;
  verified?: boolean;
  reviztoId?: string;
  reviztoIfcGuid?: string;
  exportedToBcf?: boolean;
  partialProgressPercent?: number;
  trade?: string;

  static isBuilt = (element: PlannedBuildingElement, scanDate: Date) => {
    return element?.builtAt != null && DateConverter.isOnOrAfterDay(element.builtAt, scanDate);

  };

  static isFixed = (element: PlannedBuildingElement, scanDate: Date) => {
    return element?.fixedAt != null && DateConverter.isOnOrAfterDay(element.fixedAt, scanDate);

  };

  static isDeviated = (element: PlannedBuildingElement, scanDate: Date) => {
    return !!PlannedBuildingElement.getDeviation(element, scanDate);
  };

  static getScanLabel = (element: PlannedBuildingElement, scanDate: Date) => {
    if (PlannedBuildingElement.isDeviated(element, scanDate)) {
      return DEVIATED;
    } else if (PlannedBuildingElement.isBuilt(element, scanDate)) {
      return IN_PLACE;
    } else {
      return NOT_BUILT;
    }
  };

  static getDeviation = (element: PlannedBuildingElement, scanDate: Date): Deviation => {
    if (PlannedBuildingElement.isFixed(element, scanDate)) {
      if (Deviation.isEmpty(element?.fixedDeviation)) {
        return null;
      } else {
        return element?.fixedDeviation;
      }
    } else if (PlannedBuildingElement.isBuilt(element, scanDate)) {
      if (Deviation.isEmpty(element?.deviation)) {
        return null;
      } else {
        return element?.deviation;
      }
    } else {
      return null;
    }
  };
}
