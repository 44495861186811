import _ from "underscore";

import { camelCaseArrayWords } from "../services/converters/camel_case";
import { CLASH, DEVIATED, IN_PLACE, NOT_BUILT } from "../models/domain/enums/scan_label";
import { INCLUDED } from "../models/domain/enums/deviation_status";

const rootStyles = window.getComputedStyle(document.documentElement);

const jsPropertyNamesToCSSPropertyNames = {
  // Colors
  almostBlackGray8: "--almost-black-gray-8",
  almostBlackGray50: "--almost-black-gray-50",
  almostBlackGray: "--almost-black-gray",
  almostWhiteGray50: "--almost-white-gray-50",
  almostWhiteGray: "--almost-white-gray",
  annotationHoveredClash: "--annotation-hovered-clash",
  annotationHoveredError: "--annotation-hovered-error",
  annotationHoveredResolved: "--annotation-hovered-dismissed",
  annotationLevelClash: "--annotation-level-clash",
  annotationLevelError: "--annotation-level-error",
  annotationLevelResolved: "--annotation-level-dismissed",
  annotationSelectedClash: "--annotation-selected-clash",
  annotationSelectedError: "--annotation-selected-error",
  annotationSelectedResolved: "--annotation-selected-dismissed",
  asBuiltTeal: "--bim-as-built",
  avvirDarkGold: "--avvir-dark-gold",
  avvirDarkGray: "--avvir-dark-gray",
  avvirExtraLightGold: "--avvir-gold-50",
  avvirGold8: "--avvir-gold-8",
  avvirGold: "--avvir-gold",
  avvirGray: "--avvir-gray",
  avvirLightGold: "--avvir-light-gold",
  avvirLightGray: "--avvir-light-gray",
  avvirSecondaryBlue: "--avvir-secondary-blue",
  avvirSecondaryDarkBlue: "--avvir-secondary-dark-blue",
  avvirSecondaryLightBlue: "--avvir-secondary-light-blue",
  black: "--black",
  black5: "--black-5",
  black12: "--black-12",
  black30: "--black-30",
  black60: "--black-60",
  black80: "--black-80",
  darkGray: "--dark-gray",
  deviationErrorRed: "--deviation-error-red",
  deviationDismissedGreen: "--deviation-dismissed-green",
  disabled: "--disabled",
  extraLightGray: "--extra-light-gray",
  failurePink: "--failure-pink",
  gray: "--gray",
  insufficientDataDarkGray: "--insufficient-data-dark-gray",
  lightGray: "--light-gray",
  mediumGray: "--medium-gray",
  obstructedPeach: "--obstructed-peach",
  offWhite: "--off-white",
  primaryContainedHoverBackground: "--avvir-hover-gold",
  secondaryContainedHoverBackground: "--avvir-hover-gray",
  selectionBlue: "--bim-selection",
  successGreen: "--success-green",
  unpushedGray: "--unpushed-gray",
  "BIM/Built": "--bim-built",
  "BIM/Not Built": "--bim-not-built",
  "BIM/Deviation": "--bim-deviation",
  "BIM/Clash": "--bim-clash",
  "BIM/Included": "--bim-included",
  "BIM/Verified": "--bim-verified",
  "BIM/Ignored": "--bim-ignored",
  "Status/Not Started": "--status-not-started",
  "Status/In Progress": "--status-in-progress",
  "Status/Completed": "--status-completed",
  white: "--white",

  // Sizes
  smallestFontSize: "--smallest-font",
  smallFontSize: "--small-font",
  smallMediumFontSize: "--small-medium-font",
  mediumFontSize: "--medium-font",
  mediumLargeFontSize: "--medium-large-font",
  largeFontSize: "--large-font",
  defaultIconSize: "--default-icon-size",
  largeIconSize: "--large-icon-size",
  halfSpace: "--half-space",
  space: "--space",
  space2x: "--space-x2",
  space3x: "--space-x3",
  space4x: "--space-x4",
  navBarHeight: "--nav-bar-height",
  filterBarHeight: "--filter-bar-height",
};

export type GlobalStyles = {
  almostBlackGray8: string
  almostBlackGray50: string
  almostBlackGray: string
  almostWhiteGray50: string
  almostWhiteGray: string
  annotationHoveredClash: string
  annotationHoveredError: string
  annotationHoveredResolved: string
  annotationLevelClash: string
  annotationLevelError: string
  annotationLevelResolved: string
  annotationSelectedClash: string
  annotationSelectedError: string
  annotationSelectedResolved: string
  asBuiltTeal: string
  avvirDarkGold: string
  avvirDarkGray: string
  avvirExtraLightGold: string
  avvirGold8: string
  avvirGold: string
  avvirGray: string
  avvirLightGold: string
  avvirLightGray: string
  avvirSecondaryBlue: string
  avvirSecondaryDarkBlue: string
  avvirSecondaryLightBlue: string
  black: string
  black5: string
  black12: string
  black30: string
  black60: string
  black80: string
  darkGray: string
  deviationDismissedGreen: string
  deviationErrorRed: string
  disabled: string
  extraLightGray: string
  failurePink: string
  gray: string
  insufficientDataDarkGray: string
  lightGray: string
  mediumGray: string
  obstructedPeach: string
  offWhite: string
  primaryContainedHoverBackground: string
  secondaryContainedHoverBackground: string
  selectionBlue: string
  successGreen: string
  unpushedGray: string
  "BIM/Built": string
  "BIM/Not Built": string
  "BIM/Deviation": string
  "BIM/Clash": string
  "BIM/Included": string
  "BIM/Verified": string
  "BIM/Ignored": string
  "Status/Not Started": string
  "Status/In Progress": string
  "Status/Completed": string
  white: string

  smallestFontSize: number
  smallFontSize: number
  smallMediumFontSize: number
  mediumFontSize: number
  mediumLargeFontSize: number
  largeFontSize: number
  defaultIconSize: number
  largeIconSize: number
  halfSpace: number
  space: number
  space2x: number
  space3x: number
  space4x: number
  navBarHeight: number
  filterBarHeight: number
}

type CssVariables = GlobalStyles

function getCssVariablesAsObject(): CssVariables {
  const styleBlocks = Array.from(document.querySelectorAll("style"));
  const computedProperties: GlobalStyles = _.mapObject(jsPropertyNamesToCSSPropertyNames, (cssPropertyName) => {
      let value: number | string = rootStyles.getPropertyValue(cssPropertyName).trim();
      if (value.endsWith("px")) {
        value = parseFloat(value.slice(0, -2));
      }
      if (value === "" && cssPropertyName.includes("space")) {
        value = Math.round(Math.random() * 100);
      }
      if (value === "") {
        value = _.sample(["#0fa", "#a0f", "#f02", "#ff2", "#ff0", "#0ff", "#444", "#9fa", "#cc0", "#cc4"]);
      }
      return value;
    }
  ) as GlobalStyles;

  return styleBlocks.reduce((cssVariablesMap, block) => {
    const blockCssVariables = block.innerHTML.match(/--.+?:.+?;/g);
    if (blockCssVariables && blockCssVariables.length) {
      blockCssVariables.forEach((variable) => {
        if (typeof variable === "string") {
          const keyVal = variable.split(":");
          const key = keyVal[0].slice(2);
          const camelCaseVariableName = key.split("-").map(camelCaseArrayWords).join("");
          if (!cssVariablesMap[camelCaseVariableName]) {
            let value: number | string = keyVal[1].slice(1, -1);
            if (value.endsWith("px")) {
              value = parseFloat(value.slice(0, -2));
            }
            cssVariablesMap[camelCaseVariableName] = value;
          }
        }
      });
    }
    return cssVariablesMap;
  }, computedProperties);
}

const globalStyles: GlobalStyles = getCssVariablesAsObject();

export const statusColorsByStatus = {
  [CLASH]: "BIM/Clash" as const,
  [IN_PLACE]: "BIM/Built" as const,
  [NOT_BUILT]: "BIM/Not Built" as const,
  [DEVIATED]: "BIM/Deviation" as const,
  [INCLUDED]: "BIM/Included" as const,
  VERIFIED: "BIM/Verified" as const,
  EXCLUDE_FROM_ANALYSIS: "BIM/Ignored" as const,
};

export default globalStyles;
