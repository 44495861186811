import React, { FunctionComponent } from "react";
import { connect } from "react-redux";

import "../../../css/nav_bar/nav_bar_controls.css"
import getPageName from "../../services/getters/location_metadata/get_page_name";
import NavBarControlsExplorer from "./nav_bar_controls_explorer";
import NavBarKebabMenu from "./nav_bar_kebab_menu";
import PageNames from "../../models/domain/enums/page_names";
import NavBarControlsWbs from "./nav_bar_controls_wbs";
import NavBarPanelsMenu from "./nav_bar_panels_menu";


export type Props = ReturnType<typeof mapStateToProps>;

export const NavPageControls: FunctionComponent<Props> = (props) => {
  const { pageName } = props;

  let pageControls = null;
  switch(pageName) {
    case PageNames.PROJECT_VIEWER:
      pageControls = <NavBarControlsExplorer/>;
      break;
    case PageNames.WORK_BREAKDOWN_STRUCTURE:
      pageControls = <NavBarControlsWbs/>
      break;
    case PageNames.PROJECT_SUMMARY:
      pageControls = <>
        <NavBarPanelsMenu/>
        <NavBarKebabMenu/>
      </>
      break;
    default:
      pageControls = <NavBarKebabMenu/>;
  }
  return <div className="NavBarControls">
    {pageControls}
  </div>;
};

const mapStateToProps = (state, props) => ({
  pageName: getPageName(state, props),
});

export default connect(mapStateToProps)(NavPageControls);
